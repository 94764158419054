import {
  APP_USER_STATUS_STATISTICS_URL,
  APP_DASHBOARD_LOANS_BY_CATEGORY_COUNT_URL,
  APP_DASHBOARD_LOANS_COUNT_URL,
  APP_DASHBOARD_LOGIN_COUNT_URL,
  APP_DASHBOARD_PAYMENTS_COUNT_URL,
  APP_DASHBOARD_REGISTRATION_COUNT_URL,
} from "../../../utils/Endpoints";

import { getStatsData } from "./API";

export interface LoginCountSummary {
  logins: number;
  prevLogins: number;
  hourly: HourlyLoginCountSummary[];
}

export interface HourlyLoginCountSummary {
  time: number;
  count: number;
}

export interface LoanStatisticsSummary {
  completed: number;
  rejected: number;
  pending: number;
  completedPer: number;
  rejectedPer: number;
  pendingPer: number;
  data: LoanStatisticsItem[];
}

export interface LoanStatisticsItem {
  type: string;
  items: TimeCountItem[];
}

export interface TimeCountItem {
  time: number;
  count: number;
}

export interface PayoutStatisticsSummary {
  installments: number;
  prevInstallments: number;
  data: TimeCountItem[];
}

export interface RegistraionStatisticsSummary {
  incompleteRegistrationSummary: RegistrationCountSummary;
  registrationSummary: RegistrationCountSummary;
}

export interface userStatisticsSummary {
  activeUserSummary: userCountSummary;
  blockedUserSummary: userCountSummary;
  deactiveUserSummary: userCountSummary;
}

export interface userCountSummary {
  PresentCount: number;
  prevCount: number;
  hourlyCount: HourlyLoginCountSummary[];
}

export interface RegistrationCountSummary {
  registrations: number;
  prevRegistration: number;
  hourlyRegistration: HourlyLoginCountSummary[];
}

export const fetchLoginData = async (setLoginStat) => {
  const loginCountSummary = await getStatsData(APP_DASHBOARD_LOGIN_COUNT_URL);
  setLoginStat(loginCountSummary);
};

export const fetchRegistrationData = async (setRegistrationStat) => {
  const regCountSummary = await getStatsData(
    APP_DASHBOARD_REGISTRATION_COUNT_URL
  );
  setRegistrationStat(regCountSummary);
};

export const fetchLoansData = async (setLoansStatData) => {
  const loansCountSummary = await getStatsData(APP_DASHBOARD_LOANS_COUNT_URL);
  setLoansStatData(loansCountSummary);
};

export const fetchLoansByCategoryData = async (setLoansCategoryStatData) => {
  const loansCategoryData = await getStatsData(
    APP_DASHBOARD_LOANS_BY_CATEGORY_COUNT_URL
  );
  setLoansCategoryStatData(loansCategoryData);
};

export const fetchPaymentsCategoryData = async (
  setPaymentsCategoryStatsData
) => {
  const paymentCategoryData = await getStatsData(
    APP_DASHBOARD_PAYMENTS_COUNT_URL
  );
  setPaymentsCategoryStatsData(paymentCategoryData);
};

export const fetchUserStatusStatistics = async (setUserStatusStatistics) => {
  const userStatusStatisticsData = await getStatsData(
    APP_USER_STATUS_STATISTICS_URL
  );
  console.log("userStatusStatisticsData ", userStatusStatisticsData);
  setUserStatusStatistics(userStatusStatisticsData);
};
