import React, { useEffect, useState } from "react";
import Search from "../../assets/images/icon-search.svg";
import Arrow from "../../assets/images/pagination_arrow.svg";
import Filter from "../../assets/images/filter.svg";
import { getAllIbanRequests } from "./hook/API";
import {
  exportData,
  filters,
  IbanStatus,
  IbanStatusType,
  sortBanks,
} from "./hook/ScreenHelper";
import "./../../App.css";
import i18next from "i18next";
import { useAppSelector } from "../../hooks/hooks";
import { Bank, getBanksList } from "../../main_apis/GetBanksList";
import { useTranslation } from "react-i18next";
import { TableRowPlaceholder } from "../../components/TableRowPlaceholder/TableRowPlaceholder";
import { useNavigate } from "react-router-dom";
import { updateStatus } from "../DashboardCertificate/hook/API";
import Moment from "react-moment";
import moment from "moment";
import Collapsible from "react-collapsible";
// import Collapse from "react-collapse";

export const Dashboard: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { loading } = useAppSelector((state) => state.settings);
  const [page, setPage] = useState<number>(1);

  const [filters, setFilters] = useState<filters>({
    status: IbanStatus.TO_BE_VERIFIED,
    page,
    includeRejectedLoans: false,
  });

  const [inputs, setInputs] = useState<filters>({
    status: IbanStatus.TO_BE_VERIFIED,
    page,
    includeRejectedLoans: false,
  });

  const [totalCount, setTotalCount] = useState<number>(0);
  const [data, setData] = useState([]);
  const [banks, setBanks] = useState<Bank[]>([]);
  const [timer, setTimer] = useState(null);

  const customerIdSorted = (items) => {
    if(items.length == 0) return [];
    let data = items?.sort((a, b) => a.customerId - b.customerId);

    var currentID = data[0]?.customerId;
    var res = [];
    var finalData = [];

    for (var i = 0; i < data.length; i++) {
      if (currentID !== data[i]?.customerId) {
        currentID = data[i]?.customerId;
        finalData?.push(res);
        res = [];
      }
      res?.push(data[i]);
    }
    finalData?.push(res);
    finalData?.sort((a: Date, b: Date) => {
      const momentA = moment(a[0].createdAt);
      const momentB = moment(b[0].createdAt);
      return momentB.diff(momentA);
    });
    
    return finalData;
  };

  // we calling this function when we need to get all records fromdatabase
  const callRequest = async (initFilters?: filters) => {
    setData([]);
    await getAllIbanRequests(initFilters ?? filters, (_, data) => {
      setData(customerIdSorted(data?.ibanRequests));
      setTotalCount(data?.totalCount);
    });
  };

  // this function helping us to change the status of the request.
  const changeIbanStatus = (reqId: string, status: IbanStatusType) => {
    updateStatus(
      { requestId: reqId, ibanStatus: status, reason: "" },
      (success, _) => success && callRequest()
    );
  };

  const searchInputChanged = (value) => {
    setInputs({ ...inputs, search: value });
    setFilters({ ...filters, search: value });

    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      callRequest({ ...filters, search: value });
    }, 500);

    setTimer(newTimer);
  };

  const changePage = (currentPage: number) => {
    setFilters({ ...filters, page: currentPage });
    setPage(currentPage);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    callRequest();
  }, [page]);
  useEffect(() => {
    getBanksList((_, banks) => setBanks(banks));
  }, []);

  const paginationElemnts = (
    currentPage: number,
    length: number
  ): JSX.Element[] => {
    let pages: JSX.Element[] = [];
    let totalPage = Math.ceil(length / 10);
    const first = (
      <li
        key={1}
        className={"page-item" + (1 === currentPage ? " active" : "")}
      >
        <button className="btn btn-link" onClick={(e) => changePage(1)}>
          1
        </button>
      </li>
    );
    const last = (
      <li
        key={totalPage}
        className={"page-item" + (totalPage === currentPage ? " active" : "")}
      >
        <button className="btn btn-link" onClick={(e) => changePage(totalPage)}>
          {totalPage}
        </button>
      </li>
    );

    if (currentPage - 1 > 1) pages.push(first);
    for (let i = currentPage - 1; i <= currentPage + 1; i++) {
      if (i > 0 && i <= totalPage) {
        const element = (
          <li
            key={i.toString()}
            className={"page-item" + (i === currentPage ? " active" : "")}
          >
            <button className="btn btn-link" onClick={(e) => changePage(i)}>
              {i}
            </button>
          </li>
        );
        pages.push(element);
      }

      if (i === currentPage && data.length < 10) break;
    }
    if (totalPage - currentPage > 1) pages.push(last);

    return pages;
  };
  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <div className="row table-header">
              <div className="col-md-11 col-lg-11">
                <div className="input-group search-input align-items-center">
                  <span className="input-group-text">
                    <img alt="" src={Search} />
                  </span>
                  <input
                    type="text"
                    className="form-select"
                    placeholder={t("search_input_placeholder")}
                    onChange={(e) => searchInputChanged(e.target.value)}
                    value={inputs.search}
                  />
                  {inputs.search && (
                    <button
                      type="button"
                      className="btn-close ps mx-3"
                      aria-label="Close"
                      onClick={() => searchInputChanged("")}
                    ></button>
                  )}
                </div>
              </div>
              <div className="col-md-1 col-lg-1 d-flex justify-content-center">
                <div
                  className="d-flex align-items-center justify-content-center"
                  role="toolbar"
                  aria-label="Toolbar with button groups"
                >
                  <div
                    className="btn-group mx-2"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      className="btn bg-gradient-dark"
                      type="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasExample"
                      aria-controls="offcanvasExample"
                      data-bs-placement="top"
                      title="Filter"
                    >
                      <img alt="" src={Filter} />
                    </button>
                  </div>

                  <button
                    className="btn bg-gradient-dark"
                    onClick={() => exportData(filters, t)}
                    type="button"
                    data-bs-toggle="offcanvas"
                    aria-controls="offcanvasExample"
                    data-bs-placement="top"
                    title="Download"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-download"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 ">
            <div className="table-responsive" style={{ minHeight: "25rem" }}>
              <table className="table table-hover my-table">
                <thead
                  className="rounded"
                  style={{ backgroundColor: "#FAFBFC" }}
                >
                  <div className="row">
                    <th className="col-1">{t("inputs.date").toString()}</th>
                    <th className="col-2">{t("inputs.customer").toString()}</th>
                    <th className="col-1">{t("inputs.bank").toString()}</th>
                    <th className="col-2">{t("inputs.iban").toString()}</th>
                    <th className="col-1">
                      {t("inputs.modifiedAt").toString()}
                    </th>
                    <th className="col-2">
                      {t("inputs.certificates").toString()}
                    </th>
                    <th className="col-2">{t("inputs.status").toString()}</th>
                    <th className="col-1"></th>
                  </div>
                </thead>
                <tbody>
                  {loading && <TableRowPlaceholder />}

                  {data.length && data?.map((item, key) => (
                    <Collapsible
                      trigger={
                        <div key={key} className="row">
                          <td className="col-1">
                            <Moment
                              format="YYYY-MM-DD HH:mm:ss"
                              tz="Asia/Riyadh"
                            >
                              {item[0]?.createdAt}
                            </Moment>
                          </td>
                          <td className="col-2">
                            <strong>{item[0]?.customerName}</strong>
                            {item[0]?.arabicName ? (
                              <p className="gray">
                                <strong>{item[0]?.arabicName}</strong>
                              </p>
                            ) : null}
                            <p className="gray">
                              <strong>{item[0]?.customerId}</strong>
                            </p>
                          </td>
                          <td className="col-1">
                            <strong>{item[0]?.bankName}</strong>
                          </td>
                          <td className="col-2">{item[0]?.iban}</td>
                          <td className="col-1">
                            <Moment
                              format="YYYY-MM-DD HH:mm:ss"
                              tz="Asia/Riyadh"
                            >
                              {item[0]?.modifiedAt}
                            </Moment>
                          </td>
                          <td className="col-2">
                            <button
                              className="btn btn-link"
                              onClick={(e) =>
                                navigate("/certificate", {
                                  state: { data: item[0] },
                                })
                              }
                            >
                              {t("inputs.view_certificate").toString()}
                            </button>
                          </td>
                          <td className="col-2">
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Button group with nested dropdown"
                            >
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className={
                                    "btn btn-my-dropdown " + item[0]?.ibanStatus
                                  }
                                  data-bs-toggle={null}
                                  aria-expanded="false"
                                >
                                  {t("status." + item[0]?.ibanStatus).toString()}
                                </button>
                                <ul className="dropdown-menu">
                                  <li
                                    key={
                                      key.toString() +
                                      "1" +
                                      item[0]?.customerName
                                    }
                                  >
                                    <button
                                      className="dropdown-item"
                                      onClick={() =>
                                        changeIbanStatus(
                                          item[0]?.requestId,
                                          IbanStatus.VERIFIED
                                        )
                                      }
                                    >
                                      {t("status.VERIFIED").toString()}
                                    </button>
                                  </li>
                                  <li
                                    key={
                                      key.toString() +
                                      "2" +
                                      item[0]?.customerName
                                    }
                                  >
                                    <button
                                      className="dropdown-item text-danger"
                                      onClick={() =>
                                        changeIbanStatus(
                                          item[0]?.requestId,
                                          IbanStatus.REJECTED
                                        )
                                      }
                                    >
                                      {t("status.REJECTED").toString()}
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                          <td className="col-1">
                            <div
                              className="btn-group"
                              role="group"
                              aria-label="Button group with nested dropdown"
                            >
                              {item.length > 1 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  width="24"
                                  height="24"
                                  fill="currentColor"
                                >
                                  <path d="M12 16l-6-6h12z" />
                                </svg>
                              )}
                            </div>
                          </td>
                        </div>
                      }
                    >
                      {item?.map(
                        (item1, key1) =>
                          key1 > 0 && (
                            <div
                              className="d-flex justify-content-between p-0"
                              key={key + key1}
                            >
                              <td className="col-1" style={{ color: "#fff" }}>
                                <small>
                                  <Moment
                                    format="YYYY-MM-DD HH:mm:ss"
                                    tz="Asia/Riyadh"
                                  >
                                    {item1?.createdAt}
                                  </Moment>
                                </small>
                              </td>
                              <td className="col-2" style={{ color: "#fff" }}>
                                <strong>{item1?.customerName}</strong>
                                {item1?.arabicName ? (
                                  <p className="gray" style={{ color: "#fff" }}>
                                    <strong>{item1?.arabicName}</strong>
                                  </p>
                                ) : null}
                                <p className="gray" style={{ color: "#fff" }}>
                                  <strong>{item1?.customerId}</strong>
                                </p>
                              </td>
                              <td className="col-1">
                                <strong>{item1?.bankName}</strong>
                              </td>
                              <td className="col-2">{item1?.iban}</td>
                              <td className="col-1">
                                <small>
                                  <Moment
                                    format="YYYY-MM-DD HH:mm:ss"
                                    tz="Asia/Riyadh"
                                  >
                                    {item1?.modifiedAt}
                                  </Moment>
                                </small>
                              </td>
                              <td className="col-2">
                                <button
                                  className="btn btn-link"
                                  onClick={(e) =>
                                    navigate("/certificate", {
                                      state: { data: item1 },
                                    })
                                  }
                                >
                                  {t("inputs.view_certificate").toString()}
                                </button>
                              </td>
                              <td className="col-2">
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Button group with nested dropdown"
                                >
                                  <div className="btn-group" role="group">
                                    <button
                                      type="button"
                                      className={
                                        "btn btn-my-dropdown " +
                                        item1?.ibanStatus
                                      }
                                      data-bs-toggle={null}
                                      aria-expanded="false"
                                    >
                                      {t(
                                        "status." + item1?.ibanStatus
                                      ).toString()}
                                    </button>
                                    <ul className="dropdown-menu">
                                      <li
                                        key={
                                          key1.toString() +
                                          "1" +
                                          item1?.customerName
                                        }
                                      >
                                        <button
                                          className="dropdown-item"
                                          onClick={() =>
                                            changeIbanStatus(
                                              item1?.requestId,
                                              IbanStatus.VERIFIED
                                            )
                                          }
                                        >
                                          {t("status.VERIFIED").toString()}
                                        </button>
                                      </li>
                                      <li
                                        key={
                                          key1.toString() +
                                          "2" +
                                          item1?.customerName
                                        }
                                      >
                                        <button
                                          className="dropdown-item text-danger"
                                          onClick={() =>
                                            changeIbanStatus(
                                              item1?.requestId,
                                              IbanStatus.REJECTED
                                            )
                                          }
                                        >
                                          {t("status.REJECTED").toString()}
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </td>
                              <td className="col-1">
                                <div
                                  className="btn-group"
                                  role="group"
                                  aria-label="Button group with nested dropdown"
                                  style={{ color: "#fff" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    fill="currentColor"
                                  >
                                    <path d="M12 16l-6-6h12z" />
                                  </svg>
                                </div>
                              </td>
                            </div>
                          )
                      )}
                    </Collapsible>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 ">
              <div className="table-footer">
                <div className="results-count">
                  <p></p>
                </div>
                <div className="results-pagination">
                  <nav>
                    <ul className="pagination">
                      <li key={"9991"} className="page-item">
                        <button
                          className="prev page-link"
                          disabled={page <= 1}
                          onClick={() => changePage(page - 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                      {paginationElemnts(page, totalCount)}
                      <li key={"9992"} className="page-item">
                        <button
                          className="next page-link"
                          disabled={
                            data?.length < 10 || page === totalCount / 10
                          }
                          onClick={() => changePage(page + 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="offcanvas offcanvas-end offcanvas-filter"
        tabIndex={-1}
        id="offcanvasExample"
        aria-labelledby="offcanvasExampleLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasExampleLabel">
            {t("filter") as string}
          </h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div className="offcanvas-body">
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput1"
              className="htmlForm-label"
            >
              {t("inputs.date") as string}
            </label>
            <input
              type="date"
              value={inputs.date}
              className="form-select"
              id="examplehtmlFormControlInput1"
              placeholder="Select date"
              onChange={(e) => setInputs({ ...inputs, date: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput1"
              className="htmlForm-label"
            >
              {t("inputs.bank") as string}
            </label>
            <select
              value={inputs.bank}
              className="form-select selectpicker"
              aria-label="Default select example"
              onChange={(e) => setInputs({ ...inputs, bank: e.target.value })}
            >
              <option value="">{t("select_bank").toString()}</option>
              {sortBanks(banks, i18n)?.map((item) => (
                <option key={item.englishName} value={item.englishName}>
                  {i18next.language === "en"
                    ? item.englishName
                    : item.arabicName}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput1"
              className="htmlForm-label"
            >
              {t("inputs.status") as string}
            </label>
            <select
              value={inputs.status}
              className="form-select"
              aria-label="Default select example"
              placeholder="Select status"
              onChange={(e) => setInputs({ ...inputs, status: e.target.value })}
            >
              <option value="" disabled>
                {t("select_status").toString()}
              </option>
              <option value="TO_BE_VERIFIED,VERIFIED,REJECTED">
                {t("status.ALL") as string}
              </option>
              <option value="TO_BE_VERIFIED">
                {t("status.TO_BE_VERIFIED") as string}
              </option>
              <option value="VERIFIED">{t("status.VERIFIED") as string}</option>
              <option value="REJECTED">{t("status.REJECTED") as string}</option>
            </select>
          </div>
          <div className="mb-3">
            <label
              htmlFor="examplehtmlFormControlInput1"
              className="htmlForm-label"
            >
              {t("inputs.status") as string}
            </label>
            <div className="d-flex">
              <input
                className="form-check"
                type="checkbox"
                id="includeRejectedLoansInput"
                checked={inputs.includeRejectedLoans}
                onChange={(e) =>
                  setInputs({
                    ...inputs,
                    includeRejectedLoans: e.target.checked,
                  })
                }
              />
              <label htmlFor="includeRejectedLoansInput" className="ms-2">
                {t("inputs.includeRejectedLoans") as string}
              </label>
            </div>
          </div>
        </div>
        <div className="offcanvas-footer">
          <div className="results">
            <strong> {data.length + " " + t("results")} </strong>
          </div>
          <div className="main-buttons">
            <button
              data-bs-dismiss="offcanvas"
              type="button"
              className="btn btn-light"
              onClick={async () => {
                setInputs({ status: IbanStatus.ALL });
                setFilters({ status: IbanStatus.ALL });
                callRequest({ status: IbanStatus.ALL });
              }}
            >
              {t("clear").toString()}
            </button>
            <button
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setFilters(inputs);
                callRequest(inputs);
              }}
            >
              {t("apply").toString()}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
