import {
  getRejectedLoans,
  getRejectedRegistration,
  getDeactivationReport,
} from "./API";
import { NavigateFunction } from "react-router-dom";

import { CustomersTracker } from "../../CustomersTracker/CustomersTracker";
import {
  getAllMarkers,
  getAllMarkersRegistration,
} from "../../CustomersTracker/hook/API";
import exportFromJSON from "export-from-json";
import { setErrorMsg } from "../../../redux/reducers/settingsSlice";
import { store } from "../../../redux/store";
import { geoMarker } from "../../CustomersTracker/hook/ScreenHelper";

export interface ReportSummaryModel {
  registrationDiffCount?: number;
  deactivationReportCount?: number;
  loanDiffCount?: number;
}

export interface filters {
  city?: string;
  country?: string;
  reason?: string;
  page?: number;
  size?: number;
  loadDeactivatedCustomersOnly?: boolean;
  loadAllDocuments?: boolean;
}

/**
 * Customer Tracking Info Model
 */
export interface CustomerTrackingInfoModel {
  keycloakId?: string;
  id: string;
  city: string;
  country: string;
  deviceName: string;
  deviceId: string;
  latitude: string;
  longitude: string;
  phoneIpAddress: string;
  operationType: string;
  createdAt: string;
  customerId: string;
  reason?: string;
  govId?: string;
  deactivationReason?: string;
}

export interface CustomerData {
  items: CustomerTrackingInfoModel[];
  totalCount: number;
}

export interface ReportFilter {
  page?: number;
  search?: string;
}

export const callLoanRejectionRequest = async (
  setData: React.Dispatch<React.SetStateAction<CustomerData>>,
  filters: ReportFilter
) => {
  setData(null);
  await getRejectedLoans(filters, (_, data) => setData(data));
};

export const callRegistrtaionRejectionRequest = async (
  setData: React.Dispatch<React.SetStateAction<CustomerData>>,
  filters: filters
) => {
  setData(null);
  console.log("data", filters);
  await getRejectedRegistration(filters, (_, data) => setData(data));
};

export const callRegistrationRejectionRequest = async (
  setData: React.Dispatch<React.SetStateAction<CustomerData>>,
  filters: ReportFilter
) => {
  setData(null);
  await getDeactivationReport(filters, (_, data) => setData(data));
};

const convertAPIResponseToCoordinates = (
  allData: geoMarker[]
): { lat: number; lng: number }[] => {
  const points: { lat: number; lng: number }[] = [];
  allData.forEach((element) => {
    points.push({
      lat: Number(element.latitude),
      lng: Number(element.longitude),
    });
  });
  return points;
};

// Open Deactivation report tracking map popup
export const showDeactivationTrackingPopup = (
  data: CustomerTrackingInfoModel,
  navigate: NavigateFunction
) => {
  getAllMarkers(data.keycloakId, 0, (success, data) => {
    if (success) {
      const points = convertAPIResponseToCoordinates(data);
      let payload = { data, points };
      store.dispatch(
        setErrorMsg({
          icon: null,
          cancelBtnTitle: points?.length ? "Full map" : "",
          cancelBtnAction: () => {
            navigate("/customers/map", { state: payload });
          },
          title: "Deactivation report map",
          message: <CustomersTracker locationData={payload} />,
        })
      );
    }
  });
};

// Open registraion report tracking map popup
export const showRegistrationTrackingPopup = (
  data: CustomerTrackingInfoModel,
  navigate: NavigateFunction
) => {
  getAllMarkersRegistration(data.id, 0, (success, data) => {
    if (success) {
      const points = convertAPIResponseToCoordinates(data);
      let payload = { data, points };
      store.dispatch(
        setErrorMsg({
          icon: null,
          cancelBtnTitle: points?.length ? "Full map" : "",
          cancelBtnAction: () => {
            navigate("/customers/map", { state: payload });
          },
          title: "Deactivation report map",
          message: <CustomersTracker locationData={payload} />,
        })
      );
    }
  });
};

export const exportToExcel = (filters, reportName) => {
  if (reportName == "LoanReport") {
    const data = getRejectedLoans(
      {
        ...filters,
        loadAllDocuments: true,
      },
      (success, data) => {
        console.log("data ==== ", data);
        if (success && data) {
          const fields = [
            "city",
            "country",
            "createdAt",
            "customerId",
            "deviceId",
            "deviceName",
            "id",
            "keycloakId",
            "latitude",
            "longitude",
            "operationType",
            "phoneIpAddress",
          ];
          const newData = [];
          data?.items?.map((item: any) => {
            return newData.push({
              city: item.city ? item.city : "",
              country: item.country ? item.country : "",
              createdAt: item.createdAt ? item.createdAt : "",
              customerId: item.customerId ? item.customerId : "",
              deviceId: item.deviceId ? item.deviceId : "",
              deviceName: item.deviceName ? item.deviceName : "",
              id: item.id ? item.id : "",
              keycloakId: item.keycloakId ? item.keycloakId : "",
              latitude: item.latitude ? item.latitude : "",
              longitude: item.longitude ? item.longitude : "",
              operationType: item.operationType ? item.operationType : "",
              phoneIpAddress: item.phoneIpAddress ? item.phoneIpAddress : "",
            });
          });
          console.log("newData", newData);
          const fileName = reportName;
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({
            data: newData,
            fileName,
            exportType,
            fields,
            withBOM: true,
          });
        }
      }
    );
  }

  if (reportName == "DeactivationReport") {
    const data = getDeactivationReport(
      {
        ...filters,
        loadAllDocuments: true,
      },
      (success, data) => {
        if (success && data) {
          const fields = [
            "allowNotification",
            "arabicFullName",
            "city",
            "country",
            "createdAt",
            "dateOfBirth",
            "deactivatedAt",
            "deactivationReason",
            "disableLoans",
            "email",
            "fullName",
            "govId",
            "id",
            "isActive",
            "isBlocked",
            "keycloakId",
            "lang",
            "modifiedAt",
            "nextAttemptTime",
            "phoneIpAddress",
            "phoneNumber",
            "status",
          ];
          const newData = [];
          data?.items?.map((item: any) => {
            return newData.push({
              allowNotification: item?.allowNotification
                ? item.allowNotification
                : "",
              arabicFullName: item?.arabicFullName ? item.arabicFullName : "",
              createdAt: item?.createdAt ? item.createdAt : "",
              dateOfBirth: item?.dateOfBirth ? item.dateOfBirth : "",
              deactivatedAt: item?.deactivatedAt ? item.deactivatedAt : "",
              deactivationReason: item?.deactivationReason
                ? item.deactivationReason
                : "",
              disableLoans: item?.disableLoans ? item.disableLoans : "",
              email: item?.email ? item.email : "",
              fullName: item?.fullName ? item.fullName : "",
              govId: item?.govId ? item.govId : "",
              id: item?.id ? item.id : "",
              isActive: item?.isActive ? item.isActive : "",
              isBlocked: item?.isBlocked ? item.isBlocked : "",
              keycloakId: item?.keycloakId ? item.keycloakId : "",
              lang: item?.lang ? item.lang : "",
              phoneNumber: item?.phoneNumber ? item.phoneNumber : "",
              phoneIpAddress: item?.phoneIpAddress ? item.phoneIpAddress : "",
              nextAttemptTime: item?.nextAttemptTime
                ? item.nextAttemptTime
                : "",
              modifiedAt: item?.modifiedAt ? item.modifiedAt : "",
              status: item?.status ? item.status : "",
              city: item?.city ? item.city : "",
              country: item?.country ? item.country : "",
            });
          });

          const fileName = reportName;
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({
            data: newData,
            fileName,
            exportType,
            fields,
            withBOM: true,
          });
        }
      }
    );
  }

  if (reportName == "signupReport") {
    const data = getRejectedRegistration(
      {
        ...filters,
        loadAllDocuments: true,
      },
      (success, data) => {
        console.log("data ==== ", data);
        if (success && data) {
          const fields = [
            "createdAt",
            "customerId",
            "deviceId",
            "deviceName",
            "id",
            "latitude",
            "longitude",
            "operationType",
            "phoneIpAddress",
            "city",
            "country",
            "reason",
          ];
          const newData = [];
          data?.items?.map((item: any) => {
            return newData.push({
              createdAt: item.createdAt ? item?.createdAt : "",
              customerId: item.customerId ? item.customerId : "",
              deviceId: item.deviceId ? item?.deviceId : "",
              deviceName: item.deviceName ? item.deviceName : "",
              id: item.id ? item.id : "",
              latitude: item.latitude ? item.latitude : "",
              longitude: item.longitude ? item.longitude : "",
              operationType: item.operationType ? item.operationType : "",
              phoneIpAddress: item.phoneIpAddress ? item.phoneIpAddress : "",
              city: item.city ? item.city : "",
              country: item.country ? item.country : "",
              reason: item.reason ? item.reason : "",
            });
          });

          const fileName = reportName;
          const exportType = exportFromJSON.types.csv;
          exportFromJSON({
            data: newData,
            fileName,
            exportType,
            fields,
            withBOM: true,
          });
        }
      }
    );
  }
};
