import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { AppSettings } from "../pages/AppSettings/AppSettings";
import { Customers } from "../pages/Customers/Customers";
import { CustomersTracker } from "../pages/CustomersTracker/CustomersTracker";
import { AML } from "../pages/AML/AML";
import { PEP } from "../pages/PEP/PEP";
import { CustomersTrackerList } from "../pages/CustomersTracker/CustomersTrackerList";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Certificate } from "../pages/DashboardCertificate/Certificate";
import DashPage from "../pages/DashPage/Dashboard";
import { Loans } from "../pages/Loans/Loans";
import { Payouts } from "../pages/Payouts/Payouts";
import { DeactivationReport } from "../pages/Reports/DeactivationReport/DeactivationReport";
import { LoanReport } from "../pages/Reports/LoanReport/LoanReport";
import { Reports } from "../pages/Reports/Reports";
import { SignupReport } from "../pages/Reports/SignupReport/SignupReport";
import { Statements } from "../pages/Statements/Statements";
import { Payments } from "../pages/Payments/Payments";
import { Roles } from "../utils/Constants";
import { hasRole } from "../utils/Helpers";

const financeRoutes = (
  <>
    <Route path="/payouts-finance" element={<Payouts />} />
    <Route path="/statements" element={<Statements />} />
    <Route path="/dashboard" element={<DashPage />} />
    <Route path="*" element={<Navigate to="/payouts-finance" />} />
  </>
);

const managerRoutes = (
  <>
    <Route path="/app_settings" element={<AppSettings />} />
    <Route path="*" element={<Navigate to="/app_settings" />} />
  </>
);

const antiFraudRoutes = (
  <>
    <Route path="/customers/map" element={<CustomersTracker />} />
    <Route path="/customers/tracking" element={<CustomersTrackerList />} />
    <Route path="/customers" element={<Customers />} />
    <Route path="/reports" element={<Reports />} />
    <Route path="/reports/signup" element={<SignupReport />} />
    <Route path="/reports/deactivation" element={<DeactivationReport />} />
    <Route path="/reports/loan" element={<LoanReport />} />
    <Route path="*" element={<Navigate to="/customers" />} />
  </>
);

const operationRoutes = (
  <>
    <Route path="/" element={<Dashboard />} />
    <Route path="/payments" element={<Payments />} />
    <Route path="/loans" element={<Loans />} />
    <Route path="/certificate" element={<Certificate />} />
    <Route path="*" element={<Navigate to="/" />} />
  </>
);

const creditRoutes = (
  <>
    <Route path="/payouts-credit" element={<Payouts />} />
    <Route path="*" element={<Navigate to="/payouts-credit" />} />
  </>
);

const helpdeskRoutes = (
  <>
    <Route path="/dashboard" element={<DashPage />} />
    <Route path="*" element={<Navigate to="/dashboard" />} />
  </>
);

const customerCareRoutes = (
  <>
    <Route path="/dashboard" element={<DashPage />} />
    <Route path="/loans" element={<Loans />} />
    <Route path="*" element={<Navigate to="/loans" />} />
  </>
);

const complianceRoutes = (
  <>
    <Route path="/aml" element={<AML />} />
    <Route path="/pep" element={<PEP />} />
    <Route path="*" element={<Navigate to="/aml" />} />
  </>
);

export const Main: FC = () => {
  return (
    <Routes>
      {hasRole(Roles.operation) && operationRoutes}
      {hasRole(Roles.manager) && managerRoutes}
      {hasRole(Roles.anti_fraud) && antiFraudRoutes}
      {hasRole(Roles.finance) && financeRoutes}
      {hasRole(Roles.credit) && creditRoutes}
      {hasRole(Roles.help_desk) && helpdeskRoutes}
      {hasRole(Roles.customer_care) && customerCareRoutes}
      {hasRole(Roles.compliance) && complianceRoutes}
    </Routes>
  );
};
