/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Search from "../../assets/images/icon-search.svg";
import Arrow from "../../assets/images/pagination_arrow.svg";
import { TableRowPlaceholder } from "../../components/TableRowPlaceholder/TableRowPlaceholder";
import { useAppSelector } from "../../hooks/hooks";
import { ALL } from "../../utils/Constants";
import {
  callRequest,
  exportData,
  filters,
  IntraStatement,
  IntraStatementList,
  paginationElemnts,
  transactionTypes,
} from "./hook/ScreenHelper";
import Moment from "react-moment";

export const Statements: React.FC = () => {
  const { t } = useTranslation();
  const { loading } = useAppSelector((state) => state.settings);

  const [page, setPage] = useState<number>(1);
  const [inputs, setInputs] = useState<filters>({ page });
  const [filters, setFilters] = useState<filters>({
    page,
    transactionType: ALL,
    loadAllDocuments: false,
  });
  const [data, setData] = useState<IntraStatementList>(null);
  const [timer, setTimer] = useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    callRequest(setData, filters);
  }, [page]);

  const changePage = (currentPage: number) => {
    setFilters({ ...filters, page: currentPage });
    setPage(currentPage);
  };

  const searchInputChanged = (value) => {
    setPage(1);
    setInputs({ ...inputs, search: value, page: 1 });
    setFilters({ ...filters, search: value, page: 1 });
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      callRequest(setData, filters, { ...filters, search: value });
    }, 500);

    setTimer(newTimer);
  };

  const changeStatementStatus = (data) => {
    setPage(1);
    let filterPayload = { ...filters, transactionType: data, page: 1 };
    setFilters(filterPayload);
    callRequest(setData, filterPayload);
  };

  return (
    <>
      <div className="box_container">
        <div className="row g-3">
          <div className="col-md-12">
            <div className="row table-header">
              <div className="col-12 col-md-12">
                <div className="navbar navbar-expand-lg">
                  <div className="container-fluid">
                    <div className="input-group search-input align-items-center">
                      <span className="input-group-text">
                        <img alt="" src={Search} />
                      </span>
                      <input
                        type="text"
                        className="form-select"
                        placeholder={t(
                          "pages.statements.searchInputPlaceHolder"
                        )}
                        onChange={(e) => searchInputChanged(e.target.value)}
                        value={inputs.search}
                      />
                      {inputs.search && (
                        <button
                          type="button"
                          className="btn-close ps mx-3"
                          aria-label="Close"
                          onClick={() => searchInputChanged("")}
                        ></button>
                      )}
                    </div>
                    <div className="dropdown  margin-end">
                      <button
                        className="btn bg-gradient-dark dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        {t(
                          "pages.statements.trnTypes." + filters.transactionType
                        )}
                      </button>
                      <ul className="dropdown-menu">
                        {transactionTypes.map((txnType) => {
                          return (
                            <li>
                              <a
                                className="dropdown-item"
                                onClick={() => changeStatementStatus(txnType)}
                              >
                                {t("pages.statements.trnTypes." + txnType)}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                    <div className="ms-2">
                      <button
                        className="btn bg-gradient-dark"
                        type="button"
                        onClick={() => exportData(filters, t)}
                        data-bs-toggle="offcanvas"
                        aria-controls="offcanvasExample"
                        title="download"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-download"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 ">
            <div className="table-responsive" style={{ minHeight: "25rem" }}>
              <table className="table table-hover my-table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: 100 }}>
                      {t("inputs.statements.postDate")}
                    </th>
                    <th scope="col">{t("inputs.statements.channel")}</th>
                    <th scope="col">{t("inputs.statements.refNum")}</th>
                    <th scope="col">{t("inputs.statements.narr1")}</th>
                    <th scope="col">{t("inputs.statements.narr2")}</th>
                    <th scope="col">{t("inputs.statements.narr3")}</th>
                    <th scope="col">{t("inputs.statements.partTrnType")}</th>
                    <th scope="col">
                      {t("inputs.statements.transactionAmount")}
                    </th>
                    <th scope="col">
                      {t("inputs.statements.closingBalanceAmount")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {loading && <TableRowPlaceholder />}

                  {data?.items?.map((item, key) => (
                    <tr key={item.refNum}>
                      <td style={{ minWidth: 140 }}>
                        <Moment format="YYYY-MM-DD HH:mm:ss" tz="Asia/Riyadh">
                          {item.postDate + " " + item.postingTime}
                        </Moment>
                      </td>
                      <td>{item.channel}</td>
                      <td>{item.refNum}</td>
                      <td>{item.narr1}</td>
                      <td>{item.narr2}</td>
                      <td>{item.narr3}</td>
                      <td
                        className={
                          item.partTrnType === "DEBIT"
                            ? "text-danger"
                            : "text-success"
                        }
                      >
                        {t("pages.statements.trnTypes." + item.partTrnType)}
                      </td>
                      <td>
                        {item.transactionAmount} {item.transactionCurrency}
                      </td>
                      <td>
                        {item.closingBalanceAmount}{" "}
                        {item.closingBalanceCurrency}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-md-12 ">
              <div className="table-footer">
                <div className="results-count">
                  <p></p>
                </div>
                <div className="results-pagination">
                  <nav>
                    <ul className="pagination">
                      <li key={"9991"} className="page-item">
                        <button
                          className="prev page-link"
                          disabled={page <= 1}
                          onClick={() => changePage(page - 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                      {paginationElemnts(
                        data?.items,
                        page,
                        data?.totalCount,
                        changePage
                      )}
                      <li key={"9992"} className="page-item">
                        <button
                          className="next page-link"
                          disabled={
                            data?.items?.length < 10 ||
                            page === data?.totalCount / 10
                          }
                          onClick={() => changePage(page + 1)}
                        >
                          <img alt="" src={Arrow} />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
